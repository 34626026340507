<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商家券管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加商家券</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片区域 -->
    <el-card>
      <div class="table"
           style="width: 70%; margin-top: 10px">
        <el-form :model="form"
                 :rules="formRules"
                 ref="formRef"
                 label-width="100px">
          <h4>活动设置</h4>
          <el-form-item label="活动名称" prop="title" >
            <el-input v-model="form.title"></el-input>
          </el-form-item>

          <h4>商品设置</h4>
          <el-form-item label="选择商品" >
            <div v-for="(item, index) in selectedData" :key="index">
              <span>{{item.title}}</span>
              &nbsp;&nbsp;
              <a style="cursor: pointer;color: red;" @click="removeGoods(item.id)">X</a>
            </div>
            <el-button size="mini" type="primary" @click="showGoodsDialog">添加</el-button>
          </el-form-item>
          <el-form-item label="商品名称" prop="goodsTitle">
            <el-input v-model="form.goodsTitle"></el-input>
          </el-form-item>
          <el-form-item label="商品封面图" prop="goodsMainPic">
            <el-upload :action="uploadURL"
                       list-type="picture-card"
                       :on-remove="mainPicRemove"
                       :headers="headerObj"
                       :file-list="goodsMainPicList"
                       :data="{fileKey: 'PROMO_VOUCHER_IMAGE'}"
                       :on-success="mainPicSuccess">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="图片集" prop="goodsPics">
            <el-upload :action="uploadURL"
                       list-type="picture-card"
                       :on-remove="picsRemove"
                       :headers="headerObj"
                       :file-list="goodsPicsList"
                       :data="{fileKey: 'PROMO_VOUCHER_DETAIL_IMAGE'}"
                       :on-success="picsSuccess">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>

          <h4>优惠设置</h4>
          <el-form-item label="优惠类型" prop="activityCategory">
            <el-radio label="1" :value="form.activityCategory">满减券</el-radio>
          </el-form-item>
          <el-form-item label="优惠规则" >
            <div style="display: flex;align-items: center;">
              指定商品消费满
              <el-input style="width: 100px;margin: 0 10px;" v-model="form.fullMoney"></el-input>
              元减
              <el-input style="width: 100px;margin: 0 10px;" v-model="form.discountMoney"></el-input>
              元，券后<span style="color: red;">xx</span>元
            </div>
          </el-form-item>
          <el-form-item label="总发券张数" >
            <div style="display: flex;align-items: center;">
              <el-input style="width: 100px;margin: 0 10px;" v-model="form.couponsSum"></el-input>
              张，总预算=¥xxx元
            </div>
          </el-form-item>
          <el-form-item label="券核销跳转" >
            <div style="display: flex;align-items: center;">
              {{couponsUrlPrefix}}
              <el-input style="width: 300px;margin: 0 10px;" placeholder="可以输入以&开头的参数（选填）" v-model="form.couponsUrl"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="使用说明">
            <el-input type="textarea" v-model="form.activityRule"></el-input>
          </el-form-item>

          <h4>时间设置</h4>
          <el-form-item label="可领时间">
            <el-row>
              <el-col :span="9" style="text-align: center;">
                <el-date-picker
                  v-model="form.canGetBeginTime"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  type="date"
                  placeholder="">
                  </el-date-picker>
              </el-col>
              <el-col :span="1" style="text-align: center;"> 至 </el-col>
              <el-col :span="9" style="text-align: center;">
                <el-date-picker
                  v-model="form.canGetEndTime"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  type="date"
                  placeholder="">
                  </el-date-picker>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="可用时间">
          <el-radio-group v-model="form.canUseTimeLimitType">
            <el-radio class="block-radio" label="0" >
              <div style="display: flex;align-items: center;">
                用户领券
                <el-input style="width: 100px;margin: 0 10px;" v-model="form.canUseDays"></el-input>
                天内有效
              </div>
            </el-radio>
            <el-radio class="block-radio" label="1" >
              <div style="display: flex;align-items: center;">
                用户领券&nbsp;&nbsp;
                <el-date-picker
                  v-model="form.canUseBeginTime"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="">
                  </el-date-picker>
                  &nbsp;&nbsp;至&nbsp;&nbsp;
                  <el-date-picker
                  v-model="form.canUseEndTime"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="">
                  </el-date-picker>
              </div>
            </el-radio>
            </el-radio-group>
          </el-form-item>
          <h4>发放限制</h4>
          <el-form-item label="每个支付宝账号领取张数" >
          <el-radio-group v-model="canGetLimit">
            <el-radio label="0">
              不限制
            </el-radio>
            <el-radio label="1">
              限制
            </el-radio>
            </el-radio-group>
            <div>
              <div style="display: flex;align-items: center;">
                <el-input style="width: 100px;margin: 0 10px;" v-model="form.canGetSum"></el-input>
                张
              </div>
              <el-checkbox v-model="form.naturalPersonLimit" true-label="1" false-label="0">
                自然人限制：如用户使用同一身份证号注册或绑定多个支付宝账号，此用户只能通过一个支付宝账号完成领取
              </el-checkbox>
              <el-checkbox v-model="form.phoneNumberLimit" true-label="1" false-label="0">
                手机号限制：如一个手机号注册或绑定多个支付宝账号，此用户仅能通过一个支付宝账号完成领取
              </el-checkbox>
            </div>
          </el-form-item>
          <h4>商家信息</h4>
          <el-form-item label="商家品牌">
            <span>1</span>
          </el-form-item>
          <el-form-item label="商家logo">
            <span>2</span>
          </el-form-item>
          <el-form-item label="客服电话">
            <span>3</span>
          </el-form-item>
          <!-- 操作 -->
          <el-form-item v-if="!isEdit">
            <el-button type="primary" class="btnAdd" @click="addGoods(1)">确认发布</el-button>
            <el-button @click="addGoods(0)">保存，暂不发布</el-button>
          </el-form-item>
          <el-form-item v-else>
            <el-button type="primary" class="btnAdd" @click="addGoods(1)">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-dialog title="添加商品" :visible.sync="dialogVisible" width="90%" >
      <el-row>
        <el-col style="display: flex;">
          <el-input v-model="pages.title" class="search-input" placeholder="商品名称" clearable />
          <el-input v-model="pages.goodsId" class="search-input" placeholder="商品ID" clearable />
          <el-input v-model="pages.remark" class="search-input" placeholder="商品描述" clearable />
          <el-select v-model="pages.status" placeholder="商品状态" class="search-select" >
            <el-option v-for="item in statusList" :key="item.value" :label="item.key" :value="item.value"/>
          </el-select>
          <el-button size="mini" @click="search" type="primary" style="margin-left: 10px;">搜索</el-button>
        </el-col>
      </el-row>
      <!-- toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      }, -->
      <el-table ref="goodsTable" :data="goodsList" @selection-change="handleSelectionChange" height="500" style="width: 100%;margin: 0;">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="商品图片" width="80">
            <template slot-scope="scope">
              <img :src="scope.row.mainPic.split(',')[0]" style="width: 60px; height: 60px; border: none" />
            </template>
          </el-table-column>
          <el-table-column prop="title" label="商品名称" />
          <el-table-column prop="goodsId" label="商品ID"  width="180"/>
          <el-table-column prop="remark" label="商品描述" />
          <el-table-column label="商品状态" width="80">
            <template slot-scope="scope" >
              {{ scope.row.status == '1' ? '上架中' : '未上架' }}
            </template>
          </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="dialog-ft" >
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="pages.currentPage"
                       :page-sizes="[1, 3, 10, 20]"
                       :page-size="pages.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
        <div>
          已选择{{selectedData.length}}件商品
          <el-button size="mini" @click="dialogVisible = false" type="primary" style="margin-left: 10px;">确认添加</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImgUpload from '../common/ImgUpload/index.vue'
export default {
  components: { ImgUpload },
  data () {
    return {
      isEdit: false,
      goodInfo: {},
      goodsList: [],
      total: 0,
      statusList: [
        {key: '全部', value: null},
        {key: '上架中', value: '1'},
        {key: '未上架', value: '0'},
      ], 
      goodsMainPicList: [],
      goodsPicsList: [],
      // 声明一个图片的地址
      uploadURL: '/api/file/uploadFileForAlipayCoupons',

      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.localStorage.getItem('token'),
      },
      // 分页
      pages: {
        status: '',
        title: '',
        goodsId: '',
        remark: '',
        currentPage: 1,
        pageSize: 10,
      },
      canGetLimit: '1',
      couponsUrlPrefix: 'alipays://platformapi/startapp?appId=2021003127636094',
      // 添加商品表单
      form: {
        title: '商家券测试活动B',
        goodsTitle: '商家券测试活动B',
        activityCategory: '1',
        goodsMainPic: [],
        couponsUrl: '&page=pages/Details/Details?goodsId=1650282414164',
        goodsPics: [],
        fullMoney: 10,
        discountMoney: 9.9,
        couponsSum: 10,
        status: 1,
        activityRule: '',
        canGetBeginTime: '',
        canGetEndTime: '',
        canUseTimeLimitType: '0',
        canUseDays: 30,
        canUseBeginTime: '',
        canUseEndTime: '',
        canGetSum: 1,
        naturalPersonLimit: '1',
        phoneNumberLimit: '1',
        goodsIds: ''
      },
      selectedData: [],
      // 商品验证规则
      formRules: {
        // title: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        // costPrice: [
        //   { required: true, message: '请输入商品原价', trigger: 'blur' },
        // ],
        // specialRate: [
        //   { required: true, message: '请输入商品名称', trigger: 'blur' },
        // ],
        // templateId: [
        //   { required: true, message: '请选择快递模板', trigger: 'blur' },
        // ],
        // postFee: [
        //   { required: true, message: '请输入邮费', trigger: 'blur' },
        // ],
        // goodsMainPic: [
        //   { required: true, message: '主图不能为空', trigger: 'change' },
        // ],
        // // stock: [{ required: true, message: '请输入商品库存', trigger: 'blur' }],
        // sort: [
        //   { required: true, message: '请输入商品排序', trigger: 'blur' },
        //   // { validator: isNum, trigger: 'blur' },
        // ],
        // goodsPics: [
        //   { required: true, message: '图片集不能为空', trigger: 'change' },
        // ],
      },
      dialogVisible: false,
    }
  },
  created () {
    const {query} = this.$route;
    if(query.id){
      this.goodInfo = localStorage.getItem('bcoupon-' + query.id);
    }
    this.getGoods()
  },
  methods: {
    removeGoods(id){
      const list = this.selectedData.filter(o => o.id != id);
      this.selectedData = [...list]
    },
    handleSelectionChange(e){
      this.selectedData = e;
    },
    // 添加商品
    addGoods (upload) {
      //  预校验

      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请填写必要的表单项')
        }
     
        let params = {...this.form};
        params.goodsMainPic = JSON.stringify(this.goodsMainPicList.map(o => ({localPicUrl: o.localPicUrl, alipayCouponsPicUrl: o.alipayCouponsPicUrl}))[0]);
        params.goodsPics = JSON.stringify(this.goodsPicsList.map(o => ({localPicUrl: o.localPicUrl, alipayCouponsPicUrl: o.alipayCouponsPicUrl})));
        params.goodsIds = JSON.stringify(this.selectedData.map(o => o.goodsId))
        params.upload = upload;
        params.couponsUrl = `${this.couponsUrlPrefix}${params.couponsUrl}`
        if(this.isEdit){
          params = {...this.goodInfo, ...params};
          delete params.stock;
          this.$api.UpdateGoods(params).then((res) => {
            if (res.code !== 200) {
              return this.$message.error('修改失败：' +res.message)
            } else {
              this.$message.success('修改成功')
            }
            localStorage.removeItem('bcoupon-' + this.goodInfo.id)
            // 刷新列表
            this.$router.push('/bcoupon')
          })
        }else{
          this.$api.activityOrderVoucherCreate(params).then((res) => {
            if (res.code !== 200) {
              return this.$message.error('添加失败：' +res.message)
            } else {
              this.$message.success('添加成功')
            }
            this.$router.push('/bcoupon')
          })
        }
      })
    },

    showGoodsDialog(){
      this.dialogVisible = true;
    },
    search(){
      this.pages.currentPage = 1;
      this.getGoods();
    },
    //  获取商品列表信息
    getGoods () {
      this.$api.GetGoodsListToPage(this.pages).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求商品失败')
        }
        this.goodsList = res.data.records
        this.total = res.data.total
      })
    },
    // 监听页码大小 也就是下拉框
    handleSizeChange (newSize) {
      this.pages.pageSize = newSize
      // 刷新列表
      this.getGoods()
    },

    // 监听当前页的转换 也就是点击数字
    handleCurrentChange (newPage) {
      this.pages.currentPage = newPage
      // 刷新列表
      this.getGoods()
    },
    mainPicRemove (file, fileList) {
      this.goodsMainPicList = []
    },
    mainPicSuccess (res) {
      this.goodsMainPicList = [{
        ...res.data,
        url: res.data.localPicUrl
      }]
    },
    picsRemove (file, fileList) {
      this.goodsPicsList = this.goodsPicsList.filter(o => o.url != file.url)
    },
    picsSuccess (res) {
      this.goodsPicsList.push({
        ...res.data,
        url: res.data.localPicUrl
      })
      this.goodsPicsList = [...this.goodsPicsList]
    },
  },
}
</script>

<style lang="scss" scoped>
  .block-radio{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .search-input {
    width:150px;
      margin-right: 5px;
  }
  .search-select{
    width:140px;
    margin-right: 5px;
  }
  .dialog-ft{
    margin-top:10px;
    display: flex;
    justify-content: space-between;
  }
</style>
